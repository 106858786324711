<template>
    <div>
        <div class="h5 font-weight-black">TERMS AND CONDITIONS FOR ONLINE GUN AUCTIONS</div>
        <div class="h6 font-weight-bold py-2">1. Applicable Law</div>
        <table>
            <tr>
                <td class="tcol-1">1.1</td>
                <td>The auction will be governed by the Laws of the Republic of South Africa.</td>
            </tr>
            <tr>
                <td class="tcol-1">1.2</td>
                <td>
                    The Firearms Control Act 60 of 2000 applies.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">1.3</td>
                <td>
                    The rules of this auction comply with section 45 of the Consumer Protection
                    Act 68 of 2008 (CPA) and Section 43(1) of the Electronic Communications and
                    Transactions Act 25 of 2002 (ECTA).
                </td>
            </tr>
            <tr>
                <td class="tcol-1">1.4</td>
                <td>
                    Section 44(1) of the ECT allowing purchasers a cooling off period does <strong>not</strong>
                    apply to online auctions.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">1.5</td>
                <td>
                    All persons, when registering as a bidder must, in doing so, comply with
                    Chapter 1 of the Financial Intelligence Centre Act (FICA).
                </td>
            </tr>
            <tr>
                <td class="tcol-1">1.6</td>
                <td>
                    The Auctioneer is hereby appointed by the Purchaser and Seller to act for and
                    on behalf of the Purchaser and Seller as agent in accordance with Section 54
                    of the Value-Added Tax Act 1991, in respect of all tax invoices, credit and/or
                    debit notes in respect of all or any goods offered at the auction or any costs of
                    transport and insurances premiums paid for and on behalf of the Purchaser
                    and Seller.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">2. Bidder Registration</div>
        <table>
            <tr>
                <td class="tcol-1">2.1</td>
                <td>
                    Any person/entity who wishes to bid on the auction must register as a bidder online
                    and in doing so comply with Chapter 1 of FICA by emailing required documentation to
                    info@sagunauctions.co.za For more information on the requirements set out in FICA
                    visit fic.gov.za.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.2</td>
                <td>
                    The online auction service is only available to legal and natural persons who can enter
                    into a legally binding contract under the laws of South Africa. Without derogating
                    from the generality thereof, the auction services are not available to minors.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.3</td>
                <td>
                    Any person registering as a bidder online is deemed to be the principle contracting
                    party and no person will be allowed to register as an agent of a third party unless
                    with the consent of the Auctioneer.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.4</td>
                <td>
                    By registering online as a bidder, the bidder consents to the collection and use of
                    personal information by the Auctioneer for the purposes of creating the registration,
                    and participation in the online auction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.5</td>
                <td>
                    Where the bidder is a company, the person completing the online registration must
                    be duly authorised by resolution of the company to register as a purchaser and shall
                    send us a certified copy of the resolution, if requested. (For purposes of this rule any
                    reference to a company will include any reference to juristic person including
                    partnerships, trusts or incorporated entities).
                </td>
            </tr>
            <tr>
                <td class="tcol-1">2.6</td>
                <td>
                    Once registered, bidders must keep their account details confidential and must not
                    permit third parties to use or access their account, as purchasers will remain liable for
                    all bids entered via their account online.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">3. Pre-inspection of goods</div>
        <table>
            <tr>
                <td class="tcol-1">3.1</td>
                <td>
                    Once a firearm / item has been entered into the auction, it may not be withdrawn nor
                    be advertised anywhere else. The product is not allowed to get sold before the
                    auction. Should the seller not adhere to the above, a fine of 15% (fifteen percent)
                    excluding vat of the purchase price will be payable to the Auctioneer.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">3.2</td>
                <td>
                    Bidders who wish to inspect the various lots which will be presented at the auction,
                    may inspect such lots online or at the seller's premises at a time and date discussed
                    before the auction. Please request this well in advance.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">3.3</td>
                <td>
                    All lots are sold �voetstoots� and the purchasers do not enjoy the protection of
                    Section 55 and 56 of the Consumer Protection Act.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">3.4</td>
                <td>
                    Any information provided regarding the authenticity, validity, authorship, period
                    culture, source, provenance, rarity, condition, importance or historical value, is
                    provided by the seller and any misrepresentation by the seller is without the
                    cooperation or knowledge of the Auctioneer. No warranty is given as to the accuracy
                    of firearms or clarity of optics. Any right of recourse because of such
                    misrepresentation shall be against the seller.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">4. Bidding Procedure</div>
        <table>
            <tr>
                <td class="tcol-1">4.1</td>
                <td>
                    The auction shall take place at the date and time as advertised or published and will
                    not be postponed or delayed enabling any member or group of the public to partake
                    in the auction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.2</td>
                <td>
                    The auction could be subject to reserved prices settled by the sellers. The Auctioneer
                    does not have to point this out prior to the sale of the said asset or lot. The
                    Auctioneer reserves the right to open the bidding or to bid on the seller's behalf in
                    order to protect such reserve.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.3</td>
                <td>
                    If the reserve price is not met, the seller can approve the sale to the highest bidder.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.4</td>
                <td>
                    Should the Auctioneer become aware of any fault on advertising material or any
                    other publication or amendment to these Rules of Auction, the Auctioneer will prior
                    to the auction point out and when necessary, amend such mistakes in the
                    advertisement or publication.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.5</td>
                <td>
                    Any bid made does not include VAT which, where applicable, will be added to the
                    bidding price for which a VAT invoice will be issued.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.6</td>
                <td>
                    During a sale for which the bidder has registered, the bidder can bid for a lot by
                    clicking on the bid button.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.7</td>
                <td>
                    The online platform will automatically count down the time. If any bid is received
                    within two minutes of a lot's closing time, the lot will automatically stay open for
                    bidding for a further two minutes and the automated bid increments will be adjusted.
                    This can continue until no bids are received within two minutes of closing for each
                    lot.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.8</td>
                <td>
                    The current bid will be displayed on the bidder's screen during the sale.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.9</td>
                <td>
                    Until the fall of the hammer as contemplated in section 45(3) of CPA, any bid may be
                    retracted or declined by the Auctioneer if not compliant with the CPA or the
                    regulations issued in terms thereof.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.10</td>
                <td>
                    The bidder acknowledges that he/she is bidding at a live auction and agrees that each
                    bid entered is binding even if submitted in error. The bidder accepts full
                    responsibility for every bid submitted through his/her online bidding account.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.11</td>
                <td>
                    Each lot is regarded as a separate sale transaction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.12</td>
                <td>
                    The sale by auction is complete at the fall of the hammer or any other practice
                    through which the Auctioneer recognises the highest bidder as the purchaser.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.13</td>
                <td>
                    In the event of the highest bid being tied, the earliest bid received or recognised
                    wins. Any further disputes between bidders will result in the lot being re-auctioned.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">4.14</td>
                <td>
                    The Auctioneer reserves the right to reject a registration online or may withdraw
                    permission for the use of the online auction service for whatever reason before or
                    during the sale.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">5. Payment</div>
        <table>
            <tr>
                <td class="tcol-1">5.1</td>
                <td>
                    As used herein, the �bid price� shall mean the price at which a lot is knocked down by
                    the auctioneer to a purchaser, and the term �purchase price� shall mean the
                    aggregate of:
                    <br />a) The bid price;
                    <br />b) Auctioneer's commission of 5% (Five percent);and
                    <br />c) VAT at 15% of the bid price.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.2</td>
                <td>
                    The Purchaser will pay the bid price plus VAT and 5% commission to the
                    Auctioneer/seller and the Seller will pay 5% commission to the Auctioneer
                    immediately upon receival of the purchase price.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.3</td>
                <td>
                    All sales and purchase prices are payable by electronic funds transfer (EFT) within 36

                    hours to the Auctioneer / seller on acceptance of the bid.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.4</td>
                <td>
                    No cash deposits will be accepted by the auctioneer. All payments are to be made by

                    means of an EFT.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.5</td>
                <td>
                    <table>
                        <tr>
                            <td colspan="2">
                                Should the purchaser not pay the purchase price within the stipulated timeframe and
                                has failed to make arrangements for payment with the Auctioneer, then the
                                Auctioneer is entitled to, after having notified the PURCHASER in writing, per email
                                or overnight courier, to remedy the breach of contract within 7 (seven) days from the
                                date of such request and may without prejudice to the Auctioneer's other rights:
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.5.1</td>
                            <td>
                                cancel the sale and treat the assets or lots as unsold lots which may again be
                                presented on an auction for sale or be sold out of hand by means of liaison services,
                                depending on the seller's mandate to the auctioneer; or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.5.2</td>
                            <td>
                                cancel the agreement and to receive and or to retain as roukoop, or as a genuine
                                estimate of damage or because of any pending ruling to be made by a court of the
                                real damage that has been suffered, any money already paid by the PURCHASER; or
                                alternatively.
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.5.3</td>
                            <td>
                                cancel the agreement and claim a fine in the amount of 15% (fifteen percent) of
                                the bid price and all legal fees on a scale as between Attorney-and-own client scale
                                from the PURCHASER.
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">5.5.4</td>
                            <td>
                                to insist on specific performance by the PURCHASER of their obligations in terms of this
                                agreement and to claim compensation and all legal fees on a scale as between
                                Attorney-and-own client scale from them.
                            </td>
                        </tr>
                    </table>
                </td>

            </tr>

            <tr>
                <td class="tcol-1">5.6</td>
                <td>
                    Both the Seller and the Purchaser consent to the jurisdiction of the Magistrate's Court as
                    contemplated in Section 45 of Act 32 of 1944 having regard to any action which the
                    Auctioneer may institute against the purchaser or seller irrespective of the cause of action.
                    Notwithstanding the aforesaid the Auctioneer will have the sole and absolute discretion to
                    institute action in any High Court with appropriate jurisdiction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.7</td>
                <td>
                    Payment of the purchase price will be due within 36 hours after the auction closed, assuming
                    that the purchaser has received the final invoice from the Auctioneer or seller.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">5.8</td>
                <td>
                    The Auctioneer has the right to cancel any transaction or bid before, during or after the
                    auction without having to provide reasons for this action.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">6. Ownership and licencing and delivery/collection</div>
        <table>
            <tr>
                <td class="tcol-1">6.1</td>
                <td>
                    Ownership in the lot or assets will remain that of the seller until the purchase price has been
                    paid in full to the seller and the licence has been approved for the
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.2</td>
                <td>
                    The sale of all lots and assets is subject to confirmation which means that the owner/
                    auctioneer must accept or reject the bid after finalization of the auction.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.3</td>
                <td>
                    Collection of any assets or lots will not be allowed until payment of the purchase price by the
                    purchaser or acceptable arrangements for payment thereof have been made by the purchaser
                    with the seller.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.4</td>
                <td>
                    Under no circumstances shall any lot requiring SAPS licensing be released to any person or
                    body not duly authorised to be in legal possession of said lot, and unless all monies due have
                    been settled in full and in accordance with the terms and conditions contained herein.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.5</td>
                <td>
                    It is recorded that that the purchaser is deemed to have assumed full liability for ownership and
                    payment in accordance with the terms and conditions contained herein irrespective of the
                    granting or not of a South African Police Service licence or permit for possession of the said lot.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.6</td>
                <td>
                    All lots requiring licences shall be retained by the Seller pending proof of issuance of said
                    licences. Should a licence for any lot, for any reason whatsoever, be declined, the purchaser
                    may require the Auctioneer or a registered arms and ammunition dealer of the purchaser's
                    choice to resell such lot on the purchaser's behalf. Such sale shall be concluded against the
                    prevailing terms and conditions of the chosen dealer at time of resale and, irrespective of the
                    Auction purchase price, at the price determined in agreement with the auction purchaser and
                    the dealer.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.7</td>
                <td>
                    It is specifically noted that the Auctioneer may in no way be liable for a shortfall if any, between
                    the auction purchase price and the resale price.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.8</td>
                <td>
                    After the auction, the buyer will receive the seller's contact details.
                </td>
            </tr>
            <tr>
                <td class="tcol-1">6.9</td>
                <td>
                    The Auctioneer is not responsible for failed deliveries or for transport or costs of transport of
                    any items sold on auction. It is the buyer's responsibility to get in touch with the seller and to
                    arrange transportation, the cost of which is for the buyer unless otherwise specified.
                </td>
            </tr>
        </table>
        <div class="h6 font-weight-bold py-2">7. Limitation of Liability</div>
        <table>
            <tr>
                <td class="tcol-1">7.1</td>
                <td>
                    <table>
                        <tr>
                            <td colspan="2">
                                Notwithstanding section 43 of ECTA, under no circumstances will SA Gun Auctions, its officers,
                                directors, employees, or agents be liable for any loss or damage caused by:
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">7.1.1</td>
                            <td>
                                By being exposed to information contained on the site, or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">7.1.2</td>
                            <td>
                                The defamatory, offensive, or illegal conduct of other users or third parties, or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">7.1.3</td>
                            <td>
                                The use or inability to use the website or the services or content provided from or
                                through the website, or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">7.1.4</td>
                            <td>
                                Any disruption in the internet connection resulting in the bidder being disconnected
                                from the online auction for whatsoever reason, or
                            </td>
                        </tr>
                        <tr>
                            <td class="tcol-1">7.1.5</td>
                            <td>
                                The failure to record a bid because of being disconnected or the online platform going
                                offline during the auction process.
                            </td>
                        </tr>

                    </table>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
    export default {
        name: 'TermsAndConditions',
    }
</script>